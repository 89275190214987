.container {
  --button-wrapper-justify-content: center;
  --_height: 100svh;

  background-color: var(--color-surface-solid-dark);

  @media (width >= 1350px) {
    --_height: calc(100svh - (var(--spacing-xxxx-loose) * 2));

    padding-inline: var(--spacing-xx-loose);
    padding-block: var(--spacing-xxxx-loose);
  }
}

.inner {
  position: relative;
  margin-inline: auto;
  max-width: var(--max-width-revamp-base);
  height: var(--_height);
  min-height: 600px;
}

.video__wrapper {
  position: relative;
  scale: 0.5;
  z-index: 0;
  transition: border-radius 0.25s linear;
  inset: 0;
  border-radius: var(--radius-x-large, 16px);
  width: 100%;
  height: 100%;
  overflow: hidden;

  [data-hide-corners="true"] & {
    border-radius: 0;

    @media (width >= 1350px) {
      border-radius: var(--radius-x-large, 16px);
    }
  }
}

.video__video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video__overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-image: linear-gradient(
    180deg,
    rgba(0, 15, 30, 0) 0%,
    rgba(0, 15, 30, 0.62) 100%
  );
  width: 100%;
  height: 100%;
  pointer-events: none;
  content: "";
}

.teaser__container {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  z-index: 2;
  margin-inline: auto;
  inset: 0;
  max-width: 866px; /* magic number */
}
