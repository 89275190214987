.list {
  counter-reset: item;
  margin-top: var(--spacing-xx-loose);
  margin-bottom: var(--spacing-xx-loose);
  padding-left: var(--spacing-x-loose);
  width: fit-content;
  list-style: none;
}

.link {
  display: block;
  width: 100%;
  color: var(--color-on-surface);
  text-decoration: none;

  &:hover {
    border-radius: var(--radius-large);
    background: var(--color-surface-glass-dark-5);
  }
}

.item {
  position: relative;
  column-gap: var(--spacing-x-tight);
  vertical-align: middle;
  counter-increment: item;
  border-radius: var(--radius-large);
  padding-inline: var(--spacing-x-tight);
  padding-block: var(--spacing-x-tight);
  width: 100%;
  user-select: none;

  &::before {
    --_size: 24px;

    display: inline-flex; /* So that this is vertically aligned with the *first* of adjacent text */
    justify-content: center;
    align-items: center;
    margin-right: var(--spacing-x-tight);
    border-radius: var(--radius-full);
    background-color: var(--color-surface-glass-dark-10);
    width: var(--_size);
    height: var(--_size);
    overflow: clip;
    object-fit: contain;
    content: counter(item);
    font-size: 0.88em; /* Approx 16px relative to 18px parent */
    text-align: center;
  }
}
