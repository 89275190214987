.container {
  position: relative;
  margin-block: 100px;
  padding-inline: var(--spacing-sides);
}

.inner {
  margin-inline: auto;
  max-width: var(--max-width-base);
}

@media (width >= 1000px) {
  .content {
    display: grid;
    grid-column-gap: 24px;
    grid-template-columns: repeat(12, 1fr);
    min-height: 690px;
  }
}

.artwork-wrapper {
  position: relative;
  z-index: 1;
  margin-inline: auto;

  @media (width >= 650px) {
    display: flex;
    grid-row: 1 / span 2;
    grid-column: 1 / 4;
    justify-content: center;
    align-items: center;
    margin-inline: initial;
  }

  @media (width >= 1000px) {
    grid-column: 1 / 6;
  }
}

.artwork {
  display: block;
  margin-inline: auto;
  width: 100%;
  max-width: 400px;

  @media (width >= 1000px) {
    padding-bottom: 0;
    max-width: 500px;
  }
}

.title {
  margin-inline: auto;
  margin-bottom: 35px;
  color: var(--page-theme-contrast-color, var(--color-text-heading));
  font-weight: var(--font-family-heading);
  font-size: 8.3vw;
  line-height: 1.07;
  font-family: var(--font-family-heading);
  text-align: center;

  @media (width >= 650px) {
    grid-column: 4 / -1;
    align-self: end;
    margin-top: 30px;
    margin-bottom: 40px;
    max-width: 600px;
    font-size: var(--font-size-56px);
  }

  @media (width >= 1000px) {
    grid-column: 6 / -1;
    margin-top: 0;
    margin-bottom: 45px;
    max-width: none;
    text-align: left;
  }

  @media (width >= 1440px) {
    font-size: var(--font-size-72px);
    line-height: 1;
  }
}

.description {
  display: block;
  margin-inline: auto;
  max-width: 485px;
  color: var(--page-theme-contrast-color, var(--color-text-subtle));
  font-size: var(--font-size-20px);
  line-height: 1.6;
  text-align: center;

  @media (width >= 650px) {
    grid-column: 4 / -1;
  }

  @media (width >= 1000px) {
    grid-column: 6 / -1;
    opacity: 0.8;
    margin-inline: initial;
    font-size: var(--font-size-24px);
    line-height: 1.5;
    text-align: left;
  }
}
