/* This makes use of a `data-localestring` attribute if needed */
.high-sugar {
  /* Parent CSS can override these optional defaults if needed */
  --_high-sugar-mark-inset-inline-start: var(
    --high-sugar-mark-inset-inline-start,
    initial
  );
  --_high-sugar-mark-inset-inline-end: var(
    --high-sugar-mark-inset-inline-end,
    initial
  );
  --_high-sugar-mark-inset-block-start: var(
    --high-sugar-mark-inset-block-start,
    initial
  );
  --_high-sugar-mark-inset-block-end: var(
    --high-sugar-mark-inset-block-end,
    initial
  );
  --_high-sugar-mark-position: var(--high-sugar-mark-position, absolute);
  --_high-sugar-mark-transform: var(--high-sugar-mark-transform, initial);
  --_high-sugar-mark-width: var(--high-sugar-mark-width, 18px);
  --_high-sugar-mark-height: var(--high-sugar-mark-height, auto);

  position: var(--_high-sugar-mark-position);
  transform: var(--_high-sugar-mark-transform);
  inset-block-end: var(--_high-sugar-mark-inset-block-end);
  inset-block-start: var(--_high-sugar-mark-inset-block-start);
  inset-inline-end: var(--_high-sugar-mark-inset-inline-end);
  inset-inline-start: var(--_high-sugar-mark-inset-inline-start);
  width: var(--_high-sugar-mark-width);
  height: var(--_high-sugar-mark-height);
}

.vegetarian {
  --_vegetarian-mark-inset-inline-start: var(
    --vegetarian-mark-inset-inline-start,
    initial
  );
  --_vegetarian-mark-inset-inline-end: var(
    --vegetarian-mark-inset-inline-end,
    initial
  );
  --_vegetarian-mark-inset-block-start: var(
    --vegetarian-mark-inset-block-start,
    initial
  );
  --_vegetarian-mark-inset-block-end: var(
    --vegetarian-mark-inset-block-end,
    initial
  );
  --_vegetarian-mark-position: var(--vegetarian-mark-position, absolute);
  --_vegetarian-mark-transform: var(--vegetarian-mark-transform, initial);
  --_vegetarian-mark-width: var(--vegetarian-mark-width, 18px);
  --_vegetarian-mark-height: var(--vegetarian-mark-height, auto);

  position: var(--_vegetarian-mark-position);
  transform: var(--_vegetarian-mark-transform);
  inset-block-end: var(--_vegetarian-mark-inset-block-end);
  inset-block-start: var(--_vegetarian-mark-inset-block-start);
  inset-inline-end: var(--_vegetarian-mark-inset-inline-end);
  inset-inline-start: var(--_vegetarian-mark-inset-inline-start);
  width: var(--_vegetarian-mark-width);
  height: var(--_vegetarian-mark-height);
}
