/* Layout */
.container {
  /* Lists */
  --prose-ul-margin-top: 1.25em; /* em(20, 16), */
  --prose-ul-margin-bottom: 1.25em; /* em(20, 16), */
  --prose-ul-padding-left: 1.625em; /* em(26, 16), */
  --prose-ol-margin-top: 1.25em; /* em(20, 16), */
  --prose-ol-margin-bottom: 1.25em; /* em(20, 16), */
  --prose-ol-padding-left: 1.625em; /* em(26, 16), */
  --prose-li-margin-top: 0.5em; /* em(8, 16), */
  --prose-li-margin-bottom: 0.5em; /* em(8, 16), */
  --prose-color-counters: var(--color-text-subtle);
  --prose-color-bullets: var(--color-text-subtle);

  margin-top: 48px; /* magic number */
  margin-bottom: 48px; /* magic number */

  /* JS/CMS sets this value on HTML */
  padding-inline: var(--spacing-sides);
  color: var(--page-theme-contrast-color);

  @media (width >= 1000px) {
    margin-block: 64px; /* magic number */
  }
}

.inner {
  margin-inline: auto;
  max-width: var(--max-width-base);
}

.heading-wrap {
  font-weight: normal;
  text-align: center;
}

[data-has-custom-theme] .kicker {
  --kicker-border-color: var(--theming-kicker-border-color);
}

.heading {
  display: block;
  font-weight: var(--font-weight-heading);
  font-size: var(--font-size-28px);
  font-family: var(--font-family-heading);

  @media (width >= 1000px) {
    font-size: var(--font-size-32px); /* magic number */
  }

  * + & {
    margin-top: 20px; /* magic number */
  }
}

/* This could be a global style */
.text-wrap,
.introduction {
  margin-inline: auto;
  max-width: 642px;
  text-align: center;
}

* + .introduction {
  margin-top: 32px; /* magic number */

  @media (width >= 1000px) {
    margin-top: 32px; /* magic number */
  }
}

/* This could be a global style */
.text {
  font-size: var(--font-size-16px);
  line-height: 1.4;

  @media (width >= 1000px) {
    font-size: var(--font-size-20px);
  }
}

/* This could be a global style */
.text > * + * {
  margin-top: 1em;
}

.text ol,
.text ul {
  text-align: start;
}

.text ol {
  margin-top: var(--prose-ol-margin-top);
  margin-bottom: var(--prose-ol-margin-bottom);
  padding-left: var(--prose-ol-padding-left);
  list-style-type: decimal;

  &[type="A"] {
    list-style-type: upper-alpha;
  }

  &[type="a"] {
    list-style-type: lower-alpha;
  }

  &[type="I"] {
    list-style-type: upper-roman;
  }

  &[type="i"] {
    list-style-type: lower-roman;
  }

  &[type="1"] {
    list-style-type: decimal;
  }
}

.text ul {
  margin-top: var(--prose-ul-margin-top);
  margin-bottom: var(--prose-ul-margin-bottom);
  padding-left: var(--prose-ul-padding-left);
  list-style-type: disc;
}

.text li {
  margin-top: var(--prose-li-margin-top);
  margin-bottom: var(--prose-li-margin-bottom);
}

.text ol > li {
  padding-left: 0.375em; /* em(6, 16), */
}

.text ul > li {
  padding-left: 0.375em; /* em(6, 16), */
}

.text > ul > li p {
  margin-top: 0.5em; /* was 0.75 */
  margin-bottom: 0.5em; /* was 0.75 */
}

/* There isn't a solid set of WYSIWYG styles, it was felt this added too much spacing */

/* .text > ul > li > *:first-child {
  margin-top: 1.25em; // em(20, 16),
}

.text > ul > li > *:last-child {
  margin-bottom: 1.25em; // em(20, 16), 
}

.text > ol > li > *:first-child {
  margin-top: 1.25em; // em(20, 16), 
}

.text > ol > li > *:last-child {
  margin-bottom: 1.25em; // em(20, 16),
} */

.text ul ul,
.text ul ol,
.text ol ul,
.text ol ol {
  margin-top: 0.75em; /* em(12, 16), */
  margin-bottom: 0.75em; /* em(12, 16), */
}

.text ol > li::marker {
  color: var(--prose-color-counters);
  font-weight: 400;
}

.text ul > li::marker {
  color: var(--prose-color-bullets);
}

/* spacing for blocks */
.2col-text-media {
  margin-block: 64px;

  @media (width >= 1000px) {
    margin-block: 140px;
  }
}

/* The spacing between blocks and subblocks isn't that thought out, this could need refactoring... */
.blocks > :last-child {
  margin-bottom: 0;
}
