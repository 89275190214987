.container {
  margin-top: 48px;
  margin-bottom: var(--spacing-bottom);
  background-color: var(--page-theme-background-color, var(--color-bg-shade));
  padding-inline: 16px;

  @media (width >= 1000px) {
    margin-top: var(--spacing-bottom);
  }
}

.inner {
  margin-inline: auto;
  max-width: var(--max-width-sm);

  @media (width >= 1000px) {
    max-width: var(--max-width-base);
  }
}

.main {
  display: flex;
  flex-direction: column;
  box-shadow: 0 16px 32px 0 rgba(0, 30, 60, 0.12);
  border-radius: var(--radius-large);
  background-color: var(--color-surface-solid-light-lighter);
  padding: 16px;
  width: 100%;
  overflow: hidden;

  @media (width >= 1000px) {
    flex-direction: row;
    align-items: center;
    border-radius: var(--radius-x-large);
    padding: 24px;
  }
}

.image-container {
  flex-shrink: 0;
  width: 100%;
  max-width: 600px;
}

.image {
  display: block;
  border-radius: var(--radius-large);
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.copy-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-inline: auto;
  padding-inline: 24px;
  padding-top: 24px;
  padding-bottom: 52px;
  text-align: center;

  @media (width >= 1000px) {
    order: 1;
    max-width: 550px;
  }
}

.kicker {
  margin-bottom: 32px;
  font-size: var(--font-size-18px);
  white-space: pre; /* Pre really shouldn't be on headings */

  @media (width >= 1000px) {
    font-size: var(--font-size-24px);
  }
}

.headline {
  margin-bottom: 32px;
  font-weight: var(--font-weight-heading);
  font-size: var(--font-size-28px);
  line-height: 1.22;
  font-family: var(--font-family-heading);

  /* This used to have `white-space: pre;`, removed due to headings overflowing... */

  @media (width >= 650px) {
    font-size: var(--font-size-36px);
  }

  @media (width >= 1000px) {
    font-size: var(--font-size-48px);
  }
}

.copy {
  max-width: 550px;
  font-size: var(--font-size-18px);
  line-height: 1.5;

  @media (width >= 1000px) {
    font-size: var(--font-size-24px);
    line-height: 1.3;
  }
}

.disclaimer {
  display: block;
  font-size: var(--font-size-15px);

  @media (width >= 1000px) {
    font-size: var(--font-size-18px);
  }

  * + & {
    margin-top: 10px;

    @media (width >= 1000px) {
      margin-top: 20px;
    }
  }
}

.button-wrapper {
  margin-top: var(--button-wrapper-block-start);
}

.button:not([kind="link"]) {
  --cosmos-button-min-width: var(--button-min-width);
}

.legal-text {
  margin-top: 64px;
  padding-inline: 32px;
  max-width: 500px;
  color: var(--color-text-subtle);
  font-size: var(--font-size-10px);
  line-height: 1.3;
}

.video-container {
  width: 100%;
  max-width: 600px;
  height: 290px;

  @media (width >= 450px) {
    height: 370px;
  }

  @media (width >= 650px) {
    height: 450px;
  }

  @media (width >= 1000px) {
    height: 532px;
  }
}

.video {
  display: block;
  border-radius: var(--radius-large);
  width: 100%;
  height: 100%;
  object-fit: cover;
}
