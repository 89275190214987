/* Layout */
.container {
  margin-block: 48px; /* magic number */
  background-color: var(--page-theme-background-color, var(--color-bg-shade));
  padding-inline: var(--spacing-sides);

  @media (width >= 1000px) {
    margin-block: 64px; /* magic number */
  }
}

.inner {
  margin-inline: auto;
  max-width: var(--max-width-base);
}

.heading-wrap {
  font-weight: normal;
  text-align: center;

  & + * {
    margin-top: 42px; /* magic number */

    @media (width >= 1000px) {
      margin-top: 52px; /* magic number */
    }
  }
}

[data-has-custom-theme] .kicker {
  --kicker-color: var(--page-theme-contrast-color, unset);
  --kicker-border-color: var(--theming-kicker-border-color);
}

.heading {
  display: block;
  font-weight: 700;
  font-size: var(--font-size-28px);

  @media (width >= 1000px) {
    font-size: var(--font-size-64px);
  }

  * + & {
    margin-top: 20px; /* magic number */
  }
}

/* Component */
.mission {
  --c-padding-x-viewport: 5px;

  display: grid;
  position: relative;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  justify-content: center;
  align-items: center;
  transform: translateX(-50%);
  margin-left: 50%;

  /* this adds a full bleed breaking out of the container, and simulates 5px padding each side */
  width: calc(
    100vw - var(--c-padding-x-viewport) - var(--c-padding-x-viewport)
  );
  max-width: 1080px; /* magic number */
  text-align: center;

  /* visually chosen breakpoint - it can be adjusted */
  @media (width >= 650px) {
    transform: none;
    margin-inline: auto;
    width: 100%;
  }

  & + * {
    margin-top: 32px; /* magic number */

    @media (width >= 1000px) {
      margin-top: 48px; /* magic number */
    }
  }
}

.mission-background {
  display: block;
  grid-row-start: 1;
  grid-column-start: 1;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.mission-background-bulls {
  position: absolute;
  right: 0;
  bottom: 2.5%;
  left: 0;
  z-index: 2;
  margin-inline: auto;
  width: 20%;
}

.mission-text-wrap {
  grid-row-start: 1;
  grid-column-start: 1;
  z-index: 3;
  padding-inline: calc(var(--spacing-sides) + var(--c-padding-x-viewport));
  padding-top: 10%;
  padding-bottom: 15%;
  width: 100%;

  /* https://utopia.fyi/type/calculator?c=320,16,1.2,1240,42,1.25,5,2,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l&g=s,l,xl,12 */
  font-size: clamp(
    var(--font-size-16px),
    calc(0.16rem + 4.21vw),
    var(--font-size-48px)
  );

  /* visually chosen breakpoint - it can be adjusted */
  @media (width >= 650px) {
    padding-inline: 5%;
  }

  & > * + * {
    margin-top: 1em;
  }
}

/* we wrap each letter with a span in JS, then opacity 0 -> 1 
 * it's important the text is already in the DOM so it doesn't jump to a new line when typed out */
.mission-text-animated span {
  opacity: 0;

  .mission.is-playing & {
    --c-animation-delay: 0.05s; /* unitless in seconds, how long before moving onto next letter */
    --c-animation-duration: 0.025s; /* in seconds, time for each fade  */

    animation-duration: var(--c-animation-duration);
    animation-delay: calc(var(--c-index) * var(--c-animation-delay));
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-name: typewriter;
  }
}

@keyframes typewriter {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* This could be a global style */
.introduction {
  margin-inline: auto;
  max-width: 642px;
  text-align: center;
}

/* This could be a global style */
.text {
  color: var(--page-theme-contrast-color, var(--color-text));
  font-size: var(--font-size-16px);
  line-height: 1.4;

  /* This could be a global style */
  @media (width >= 1000px) {
    font-size: var(--font-size-20px);
  }
}

/* This could be a global style */
.text > * + * {
  margin-top: 1em;
}
