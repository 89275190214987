.button[data-custom-color="white"] {
  --cosmos-button-border: var(--border-thin);

  /* default styles */
  --cosmos-button-background: var(--color-surface-solid-light-lighter);
  --cosmos-button-color: var(--color-surface-solid-dark-darker);
  --cosmos-button-border-color: var(--color-surface-solid-light-lighter);

  /* hover styles */
  --cosmos-button-background-hover: var(--color-surface-glass-light-45);
  --cosmos-button-border-color-hover: var(--color-surface-glass-light-45);
  --cosmos-button-color-hover: var(--color-surface-solid-dark-darker);
}
