.container {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-end;
  isolation: isolate;
  height: 580px;

  @media (width >= 650px) {
    align-items: flex-start;
    height: 640px;
  }

  @media (width >= 1000px) {
    height: 768px;
  }
}

.text-container {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: var(--spacing-loose);
  padding: var(--spacing-xx-loose);
  padding-block-end: calc(var(--spacing-loose) + var(--spacing-xxx-loose));
  max-width: 650px;
  height: 100%;

  @media (width >= 650px) {
    justify-content: flex-end;
    align-items: flex-start;
  }

  @media (width >= 1000px) {
    padding: var(--spacing-xxxx-loose);
    padding-block-end: calc(var(--spacing-x-loose) + var(--spacing-xxxx-loose));
  }
}

.actions {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-loose);

  @media (width >= 650px) {
    flex-direction: row-reverse;
    align-items: center;
  }
}

.quote-icon {
  width: 60px;
  color: var(--cosmos-color-surface-solid-light);
  font-size: 60px;
}

.title {
  font-weight: var(--font-weight-heading);
  line-height: var(--type-xx-small-line-height);
  text-align: center;

  @media (width >= 650px) {
    text-align: start;
  }
}

.quote {
  display: none;
  font-weight: 500;

  @media screen and (width >= 650px) {
    display: flex;
  }
}

.quote-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-loose);
  text-align: center;

  @media (width >= 650px) {
    align-items: flex-start;
    text-align: start;
  }
}

.featured-image {
  position: absolute;
  transition: all 0.25s ease-in-out;
  inset: 0;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.item {
  display: flex;
  position: absolute;
  transform: scale(1);
  opacity: 0;
  z-index: 0;
  transition:
    transform 1s,
    opacity 0.3s ease;
  inset: 0;
  overflow: hidden;

  &[data-role="next"] {
    opacity: 1;
    z-index: 1;

    & .title,
    & .quote,
    & .actions,
    & .quote-icon {
      transform: translateX(10%);
      opacity: 0;
    }
  }

  &[data-role="active"] {
    --_delay-base: 0.15s;

    opacity: 1;
    z-index: 2;

    & .actions {
      transition-delay: 0s;
    }

    & .quote {
      transition-delay: calc(var(--_delay-base) * 1);
    }

    & .title {
      transition-delay: calc(var(--_delay-base) * 2);
    }

    & .quote-icon {
      transition-delay: calc(var(--_delay-base) * 3);
    }

    & .title,
    & .quote,
    & .actions,
    & .quote-icon {
      transform: translateX(0);
      opacity: 1;
      transition:
        transform 0.3s ease,
        opacity 0.25s ease;
    }

    &[data-status="animate-out"] {
      & .actions {
        transition-delay: 0s;
      }

      & .quote {
        transition-delay: calc(var(--_delay-base) * 1);
      }

      & .title {
        transition-delay: calc(var(--_delay-base) * 2);
      }

      & .quote-icon {
        transition-delay: calc(var(--_delay-base) * 3);
      }

      & .featured-image {
        transform: scale(1.1) translate(-4%, -1%);
        transform-origin: bottom right;
        opacity: 0;
      }

      & .title,
      & .quote,
      & .actions,
      & .quote-icon {
        transform: translateX(-10%);
        opacity: 0;
        transition:
          transform 0.2s ease-in,
          opacity 0.2s ease;
      }
    }
  }
}

.backdrop {
  position: absolute;
  inset: 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.5) 80%
  );
  width: 100%;
  height: 100%;
}

.content {
  display: flex;
  flex-direction: column;

  @media (width >= 1000px) {
    column-gap: 200px;
    flex-direction: row;
    align-items: center;
  }
}

.story-info {
  color: var(--color-surface-solid-light);
  line-height: var(--type-small-line-height);
}

.athlete-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing-x-tight);
  backdrop-filter: var(--backdrop-blur-medium);
  border-radius: var(--radius-full);
  background-color: var(--color-surface-glass-dark-80);
  padding: var(--spacing-xx-tight);
  padding-inline-end: var(--spacing-x-tight);
  width: fit-content;
  font-weight: 100;
  font-size: var(--font-size-14px);
}

.athlete-image {
  --athlete-size: 20px;

  border-radius: var(--radius-full, 9999px);
  width: var(--athlete-size);
  height: var(--athlete-size);
  object-fit: cover;
}

.athlete-name {
  font-size: var(--font-size-14px);
}

.carousel-indicator {
  position: absolute;
  bottom: var(--spacing-xx-loose);
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;

  @media (width >= 650px) {
    left: calc(var(--spacing-xxxx-loose) + var(--spacing-tight));
  }

  @media (width >= 1000px) {
    bottom: var(--spacing-xxx-loose);
    left: calc(var(--spacing-xxx-loose) + var(--spacing-loose));
    transform: translateX(0);
  }
}
