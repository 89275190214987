.container {
  --_background-wrapper-background-color: var(
    --background-wrapper-background-color,
    transparent
  );

  /* THEMING 
   * Some of this is a duplicates `--page-theme-{property}`, but the idea of
   * this additional layer is if we need to apply any color mixing or fallbacks etc
   */

  /* Primary color */
  --theming-color-primary: var(--page-theme-color-primary);

  /* Primary Button */
  --theming-button-background: var(--page-theme-button-background-color);
  --theming-button-background-hover: color-mix(
    in srgb,
    var(--page-theme-button-background-color),
    transparent 20%
  );
  --theming-button-color: var(--page-theme-button-color);
  --theming-button-color-hover: var(--page-theme-button-color);
  --theming-button-border-width: var(--page-theme-button-border-width);
  --theming-button-border-color: transparent;
  --theming-button-border-color-hover: transparent;

  /* Secondary button */
  --theming-secondary-button-background: color-mix(
    in srgb,
    var(--page-theme-secondary-button-background-color),
    transparent 90%
  );
  --theming-secondary-button-background-hover: color-mix(
    in srgb,
    var(--page-theme-secondary-button-background-color),
    transparent 80%
  );
  --theming-secondary-button-color: var(--page-theme-secondary-button-color);
  --theming-secondary-button-color-hover: var(
    --page-theme-secondary-button-color
  );
  --theming-secondary-button-border-width: var(
    --page-theme-secondary-button-border-width
  );
  --theming-secondary-button-border-color: transparent;
  --theming-secondary-button-border-color-hover: transparent;

  /* Tertiary button */
  --theming-tertiary-button-background: color-mix(
    in srgb,
    var(--page-theme-tertiary-button-background-color),
    transparent 100%
  );
  --theming-tertiary-button-background-hover: color-mix(
    in srgb,
    var(--page-theme-tertiary-button-background-color),
    transparent 90%
  );
  --theming-tertiary-button-color: var(--page-theme-tertiary-button-color);
  --theming-tertiary-button-color-hover: var(
    --page-theme-tertiary-button-color
  );
  --theming-tertiary-button-border-width: var(
    --page-theme-tertiary-button-border-width
  );
  --theming-tertiary-button-border-color: color-mix(
    in srgb,
    var(--theming-tertiary-button-color),
    transparent 80%
  );
  --theming-tertiary-button-border-color-hover: color-mix(
    in srgb,
    var(--page-theme-tertiary-button-background-color),
    transparent 80%
  );

  /* Link button */
  --theming-link-button-color: var(--page-theme-contrast-color);
  --theming-link-button-color-hover: var(--page-theme-contrast-color);
  --theming-link-button-border-color: var(--page-theme-contrast-color);
  --theming-link-button-border-color-hover: color-mix(
    in srgb,
    var(--page-theme-contrast-color),
    transparent 80%
  );

  /* Kicker */
  --theming-kicker-border-color: var(--page-theme-kicker-border-color);

  /* TODO: For the future, we want to theme links and underlines
   * --_wrap-accent-color: var(--theme-accent); */

  /* We need a property to stop child component margin bleeding out of this container. We need that top/bottom margin to be contained. 
   * This can be done by a border, padding, or other properties. All have pros and cons and we might run into problems with our chosen method in the future.
   * Hidden makes sure content from swipers doesn't overflow, but could crop other content.
   * Flex Column works with an additional internal wrapper (it would disabled margin collaspe on it's direct children which is no good) 
   * `inline-block` might add whitespace between vertical elements.
   * `padding-block: 0.05px` could work as the browser thinks it has padding but rounds down to 0, but browser testing is difficult */
  display: flex;
  flex-direction: column;

  /* CSS unrelated to flex */
  background-color: var(--_background-wrapper-background-color);
  width: 100%;
}

.inner {
  /* This div is intentionally blank.
   * Flex Column on the parent disables margin collapse on it's direct children. Spacing between elements needs margin collapse.
   * So this is a sacrificial div wrapper. Margin Collapse will work on the children of this element.
   */
}
