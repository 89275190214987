/* Layout */
.container {
  margin-top: 86px; /* magic number */
  margin-bottom: 78px; /* magic number */
  padding-inline: var(--spacing-sides);
  color: var(--page-theme-contrast-color, unset);

  @media (width >= 1000px) {
    margin-top: 120px; /* magic number */
    margin-bottom: 100px; /* magic number */
  }
}

.inner {
  margin-inline: auto;
  max-width: var(--max-width-base);
}

.heading-wrap {
  font-weight: normal;
  text-align: center;
}

[data-has-custom-theme] .kicker {
  --kicker-border-color: var(--theming-kicker-border-color);
}

.heading {
  max-width: 1236px; /* magic number */
  font-weight: var(--font-weight-heading);
  font-size: var(--font-size-44px);
  line-height: 1.2;
  font-family: var(--font-family-heading);

  @media (width >= 1000px) {
    font-size: var(--font-size-96px);

    &[data-size="small"] {
      font-size: var(--font-size-44px);
    }
  }

  * + & {
    margin-top: 12px; /* magic number */
  }
}

.introduction {
  margin-top: 32px; /* magic number */
  margin-inline: auto;
  max-width: 642px; /* magic number */
  text-align: center;

  @media (width >= 1000px) {
    margin-top: 52px;
  }
}

/* This could be a global style */
.text {
  font-size: var(--font-size-16px);
  line-height: 1.4;

  @media (width >= 1000px) {
    font-size: var(--font-size-20px);
  }
}

/* This could be a global style */
.text > * + * {
  margin-top: 1em;
}
