.container {
  --_card-background: var(--card-bg-color, var(--color-text-light));

  margin-top: var(--spacing-top); /* new */
  margin-bottom: var(--spacing-bottom);
  background-color: var(--page-theme-background-color, var(--color-bg-shade));
  padding-inline: var(--spacing-sides);
}

/**
 * Reduces spacing below when the next block is a [Animated]ProductTeaserCard
 */
:is(
    [data-next-type="BlocksAnimatedProductTeaserCard"],
    [data-next-type="BlocksProductTeaserCard"]
  )
  .container {
  margin-bottom: var(--spacing-sides);
}

.inner {
  margin-inline: auto;
  max-width: var(--max-width-sm);

  @media (width >= 1000px) {
    max-width: var(--max-width-base);
  }
}

.card {
  position: relative;
  transition: box-shadow 0.3s ease-in-out;
  box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.08);
  border-radius: var(--radius-large);
  background-color: var(--_card-background);
  overflow: hidden;

  @media (width >= 500px) {
    display: grid;
    grid-template-rows: repeat(8, 1fr); /* flex layout might be more suitable */
  }

  @media (width >= 650px) {
    margin-bottom: 25px;
    border-radius: var(--radius-x-large);
  }

  @media (width >= 1000px) {
    grid-template-rows: 1fr;
    grid-template-columns: repeat(8, 1fr);
    max-height: calc(1222px / 2);
  }
}

a.card {
  text-decoration: none;
}

.bg-image-wrapper {
  position: absolute;
  bottom: 3%;
  left: 0;
  z-index: 0;
  width: 100%;

  @media (width >= 1000px) {
    top: 50%;
    right: -10%;
    bottom: unset;
    left: unset;
    transform: translateY(-40%);
    width: 80%;
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0) 20%
    );
    width: 100%;
    height: 100%;
    content: "";
  }
}

.bg-image {
  width: 100%;
  height: auto;
}

.logo {
  margin-bottom: 24px;
  width: 177px;

  @media (width >= 650px) {
    margin-bottom: 32px;
    width: 225px;
  }

  @media (width >= 1000px) {
    margin-bottom: 56px;
  }
}

.text-content {
  display: flex;
  grid-row: 1 / 5;
  grid-column: 1 / -1;
  flex-direction: column;
  align-items: center;
  z-index: 5;
  padding-top: 64px;
  padding-inline: var(--spacing-sides);
  color: var(--color-text);
  text-align: center;

  @media (width >= 1000px) {
    display: block;
    grid-column: 1 / 4;
    align-self: center;
    padding-block: var(--spacing-sides);
    padding-right: 0;
    padding-left: var(--spacing-sides);
    width: 100%;
    text-align: left;
  }
}

.kicker-wrapper {
  --kicker-color: var(--color-text);

  display: block;

  & + * {
    margin-top: 16px;

    @media (width >= 650px) {
      margin-top: 20px;
    }
  }
}

.text {
  display: block;
  margin-inline: auto;
  max-width: 428px;
  font-size: var(--font-size-16px);
  line-height: 1.7;

  @media (width >= 1000px) {
    display: block;
    margin-inline: 0;
    max-width: none;
    font-size: var(--font-size-21px);
    line-height: 1.7;
  }
}

.image-wrapper {
  position: relative;
  grid-row: -6 / -1;
  grid-column: 1 / -1;
  align-self: end;
  z-index: 4;
  width: 100%;
  height: 70vw;
  max-height: 473px;

  @media (width >= 1000px) {
    grid-column: 4 / -1;
    align-self: center;
    height: 100%;
    max-height: unset;
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(
        to bottom,
        var(--_card-background),
        var(--color-transparent) 20%
      ),
      linear-gradient(
        to top,
        var(--_card-background),
        var(--color-transparent) 15%
      );
    width: 100%;
    height: 100%;
    content: "";
  }
}

.image {
  width: 95%;
  height: 100%;
  object-fit: contain;
  object-position: 50% 60%;
}

.button-wrapper {
  margin-top: 30px;

  @media (width >= 1000px) {
    margin-top: 74px;
  }
}

.button:not([kind="link"]) {
  --cosmos-button-min-width: var(--button-min-width);
}

/* TODO: Getting clarification if this component needs a vegatarian mark */

/* .dietary-mark-vegetarian {
   --vegetarian-mark-inset-block-end: 18px;
  --vegetarian-mark-inset-inline-end: 18px;
  --vegetarian-mark-width: 16px;

  z-index: 1;

  @media (width >= 650px) {
    --vegetarian-mark-width: 24px;
  }
} */
