.container {
  margin-top: 40px;
  margin-bottom: var(--spacing-bottom);
  padding-inline: var(--spacing-sides);

  @media (width >= 1000px) {
    margin-top: 95px;
  }
}

.inner {
  margin-inline: auto;
  max-width: var(--max-width-base);
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.kicker-wrapper {
  --kicker-font-size: var(--font-size-18px);

  display: block;
}

[data-has-custom-theme] .kicker {
  --kicker-color: var(--page-theme-contrast-color, unset);
  --kicker-border-color: var(--theming-kicker-border-color);
}

.title {
  display: block;
  margin-inline: auto;
  margin-block: 20px;
  max-width: 300px;
  color: var(--page-theme-contrast-color, var(--color-text-heading));
  font-weight: var(--font-weight-heading);
  font-size: var(--font-size-40px);
  line-height: 1.2;
  font-family: var(--font-family-heading);
  text-align: center;

  @media (width >= 650px) {
    margin-block: 30px;
    max-width: 410px;
    font-size: var(--font-size-56px);
  }

  @media (width >= 1000px) {
    margin-bottom: 70px;
    max-width: 600px;
  }

  @media (width >= 1440px) {
    font-size: var(--font-size-72px);
    line-height: 1;
  }
}

.content {
  position: relative;

  @media (width >= 650px) {
    display: grid;
    justify-content: center;
    background-color: transparent;
    grid-column-gap: 20px;
    grid-template-columns: repeat(8, 1fr);
  }

  @media (width >= 1000px) {
    grid-column-gap: 24px;
    grid-template-columns: repeat(12, 1fr);
  }
}

.artwork-wrapper {
  position: relative;
  z-index: 1;
  margin-inline: auto;

  @media (width >= 650px) {
    grid-column: 1 / -1;
    order: 1;
    margin-inline: initial;
  }

  @media (width >= 1000px) {
    grid-column: 1 / 6;
  }
}

.artwork {
  display: block;
  margin-inline: auto;
  margin-bottom: 30px;
  width: 100%;
  max-width: 300px;

  @media (width >= 1000px) {
    margin-bottom: 0;
    max-width: 400px;
  }
}

.text {
  position: relative;
  z-index: 1;

  @media (width >= 650px) {
    grid-column: 2 / -2;
    order: 2;
    text-align: center;
  }

  @media (width >= 1000px) {
    grid-column: 6 / -1;
    text-align: left;
  }
}

.description {
  --prose-line-height: 1.5;
  --prose-color-body: var(--page-theme-contrast-color, var(--color-text));
  --prose-link-color: var(--color-primary);

  display: block;
  margin-bottom: 30px;

  @media (width >= 1000px) {
    --prose-font-size: var(--font-size-20px);
    --prose-line-height: 1.6;
  }
}

.legal-notice {
  color: var(--page-theme-contrast-color, var(--color-text));
  font-size: var(--font-size-12px);
  line-height: 1.67;
}
