/* For accessibility Button comes before the expanded content */
.container {
  display: flex;
  flex-direction: column-reverse;
}

.button {
  --cosmos-button-color: var(--color-primary);
  --cosmos-button-color-hover: var(--color-primary-darker);
  --cosmos-button-border-color-hover: color-mix(
    in srgb,
    var(--color-primary-darker),
    transparent 80%
  );

  align-self: center;
}

[data-has-custom-theme] .button[kind="link"] {
  --cosmos-button-color: var(--theming-link-button-color);
  --cosmos-button-color-hover: var(--theming-link-button-color-hover);
  --cosmos-button-border-color: var(--theming-link-button-border-color);
  --cosmos-button-border-color-hover: var(
    --theming-link-button-border-color-hover
  );
}

.button-icon {
  transition: transform 250ms ease-in-out;

  .button[aria-expanded="true"] & {
    transform: rotate(-180deg);

    html[dir="rtl"] & {
      transform: rotate(180deg);
    }
  }
}

.content {
  /* 
     This is wrapper we use to toggle the `hidden`, it's needed because of top margin quirks.
     We have a couple inner divs to control `height: 0` -> `height: auto` effects
  */
  display: grid; /* hack - set to `block` allows for collapsing margins compared to if this was `grid` */
  grid-template-rows: 1fr; /* 0 height */
  transition: grid-template-rows 500ms;

  &[hidden] {
    grid-template-rows: 0fr;
  }
}

.content-inner {
  overflow: hidden;
}

/* This might need refactoring...
 * The idea is there is a natural paragraph space between inner content and outer content, but we don't know inner text size... */
.content-inner:first-child {
  margin-top: 1em;
}

/* as above */
.content-inner:last-child {
  margin-bottom: 1em;
}
