/* TODO: This CSS was ChatGPT'd from rb3ca-rb3 SASS, a lot of CSS can be removed */

/** 
 * TODO: we need a negative top margin to allow the rounded top corners to overlap the previous panel.
 * This isn't ideal because it visually removes 16px of the bottom spacing of the previous element.
 * We should add some JS rules and classes so we can add that 16px back as padding.
 */
.container {
  --_border-radius: var(--radius-x-large);
  --_margin-top: calc(-1 * var(--_border-radius));

  position: relative;
  margin-top: var(--_margin-top);
  border-top-right-radius: var(--_border-radius);
  border-top-left-radius: var(--_border-radius);
  background-color: var(--color-surface-solid-dark-darker);
  isolation: isolate;
  padding-inline: var(--spacing-sides);
  width: 100%;
  overflow: hidden;
  color: var(--color-text-light);
}

/* If this is a page hero no rounded corners */
[data-first-block="true"] > .container:first-child {
  --_border-radius: initial;
  --_margin-top: unset;
}

.icon {
  position: absolute;
  top: var(--spacing-loose);
  inset-inline-end: var(--spacing-loose);
}

.worb-title {
  --teaser-basic-text-align: center;
  margin: auto;
  margin-bottom: var(--spacing-xx-loose);
  max-width: 60ch; /* magic number - visually positioned */
}

.grid {
  --_gap: var(--spacing-loose);

  display: flex;
  column-gap: var(--_gap);
  row-gap: var(--_gap);
  flex-wrap: wrap;
}

.grid-item {
  display: flex;
  /* if there is a lone card on the end, it will take up the row */
  flex-grow: 1;
  min-height: 354px;

  /* on desktop and mobile, show 6 cards */
  /* &:nth-child(n + 7) {
    display: none;
  } */

  @media (650px <= width < 1000px) {
    width: calc((100% - var(--_gap)) / (100 / 50)); /* 50 % */

    &:nth-child(1) {
      width: 100%;
    }

    /* disable previously set nth-child, as it only overwrites matching rules */
    &:nth-child(n + 7) {
      display: initial;
    }

    /* on tablet, show no more than 7 cards */
    &:nth-child(n + 8) {
      display: none;
    }
  }

  @media (width >= 1000px) {
    /* this is a quarter of full width without middle gap and without the other gap */
    width: calc((100% - var(--_gap)) / (100 / 25) - var(--_gap));

    &:nth-child(1),
    &:nth-child(6n),
    &:nth-child(6n + 1) {
      flex-grow: 2;
      width: calc(
        (100% - 3 * (var(--_gap))) / (100 / 50)
      ); /* 3 is a magic number to perfectly align the layout */
    }
  }
}

.item {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: var(--radius-large);
  isolation: isolate;
  padding: 24px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  color: var(--color-text-light);

  & a {
    color: inherit;
    text-decoration: none;
  }
}

/* .text {
  position: relative;
} */

.background::after {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0,
    rgba(0, 1, 2, 0.008856) 19.98px,
    rgba(0, 3, 5, 0.0333315) 38.14px,
    rgba(0, 5, 10, 0.0703125) 54.88px,
    rgba(0, 7, 14, 0.116667) 70.52px,
    rgba(0, 9, 18, 0.169272) 85.44px,
    rgba(0, 11, 22, 0.225) 100px,
    rgba(0, 12, 24, 0.280728) 114.56px,
    rgba(0, 13, 26, 0.333333) 129.48px,
    rgba(0, 14, 28, 0.3796875) 145.12px,
    rgba(0, 15, 29, 0.4166685) 161.86px,
    rgba(0, 15, 30, 0.441144) 180.02px,
    rgba(0, 15, 30, 0.45) 200px,
    rgba(0, 15, 30, 0.8) 100%
  );
  width: 100%;
  height: 75%;
  content: "";
}

.background__image {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: transform 0.6s cubic-bezier(0.35, 0, 0, 1);
  width: 100%;
  height: 100%;
  object-fit: cover;

  .item:is(:hover, :focus-within) & {
    transform: scale(1.025);
  }
}

.title {
  margin-bottom: 8px;
}

/* Make card clickable, rather than wrapping it in an `<a>` tag */
.title-link::before {
  position: absolute;
  inset: 0;
  content: "";
}

.tag {
  display: inline-block;
  margin-top: 16px;
  border-radius: var(--radius-medium);
  background-color: var(--color-surface-glass-light-20);
  padding-inline: 8px;
  padding-block: 4px;
}

.content {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 2;
  margin-inline: auto;
  padding-block: var(--spacing-xxxx-loose);
  max-width: var(--max-width-base);
  min-height: 512px;

  @media (width >= 1000px) {
    min-height: 820px;
  }
}

.media {
  position: absolute;
  z-index: -1;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  z-index: -1;
  inset: 0;
  width: 100%;
  height: 100%;

  &::after {
    position: absolute;
    inset: 0;
    background-image: linear-gradient(
      color-mix(
        in srgb,
        var(--color-surface-solid-dark-darker) 0%,
        transparent
      ),
      color-mix(
        in srgb,
        var(--color-surface-solid-dark-darker) 70%,
        transparent
      )
    );
    width: 100%;
    height: 100%;
    content: "";
  }
}

.kicker {
  --kicker-color: var(--color-text-light);

  margin-bottom: var(--spacing-x-tight);
}

.subtitle {
  margin-top: var(--spacing-loose);
}
