.container {
  position: relative;
  background-color: var(--color-blue-dark);
  padding-top: 350px;
  padding-bottom: 50px;
  padding-inline: var(--spacing-sides);
  overflow: hidden;
  color: var(--color-text-light);

  @media (width >= 650px) {
    padding-bottom: 70px;
  }

  @media (width >= 1000px) {
    padding-inline: var(--spacing-sides);
    padding-block: var(--spacing-bottom);
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    background: linear-gradient(
      to bottom,
      rgba(0, 30, 60, 0) 60%,
      rgba(0, 30, 60, 1) 100%
    );
    width: 100%;
    height: 50%;
    content: "";
  }
}

@media (width >= 450px) {
  .container::before {
    height: 60%;
  }
}

@media (width >= 650px) {
  .container::before {
    height: 60%;
  }
}

@media (width >= 1000px) {
  .container::before {
    background-image: linear-gradient(
      to bottom,
      rgba(0, 30, 60, 0),
      rgba(0, 30, 60, 0.6)
    );
    height: 100%;
  }
}

.inner {
  margin-inline: auto;
  width: 100%;
  max-width: var(--max-width-base);
}

.background {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  min-width: 100%;
  height: 50%;

  @media (width >= 450px) {
    height: 60%;
  }

  @media (width >= 650px) {
    height: 60%;
  }

  @media (width >= 1000px) {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media (width >= 650px) {
  .inner-wrapper {
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: repeat(8, 1fr);
  }
}

.content-wrapper {
  position: relative;
  z-index: 2;

  @media (width >= 650px) {
    grid-column: 2/-2;
    text-align: center;
  }

  @media (width >= 1000px) {
    width: 41%;
    min-width: 500px;
    text-align: left;
  }
}

.description {
  --prose-font-size: var(--font-size-16px);
  --prose-line-height: 1.5;
  --prose-link-color: var(--color-primary);

  margin-bottom: 20px;

  @media (width >= 650px) {
    --prose-line-height: 1.7;
  }

  @media (width >= 1000px) {
    --prose-font-size: var(--font-size-21px);

    margin-bottom: 30px;
  }
}

.legal-notice {
  font-size: var(--font-size-12px);
  line-height: 1.67;
}
