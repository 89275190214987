.container {
  margin-top: 48px;
  margin-bottom: var(--spacing-bottom);
  background-color: var(--page-theme-background-color, var(--color-bg-shade));
  text-align: center;

  @media (width >= 1000px) {
    margin-top: var(--spacing-bottom);
  }
}

.title-kicker-wrapper {
  display: block;
  text-align: center;
}

.kicker {
  --kicker-font-size: var(--font-size-20px);
  --kicker-color: var(--page-theme-contrast-color, unset);
}

.text {
  margin: 30px auto 40px;
  max-width: 385px;
  line-height: 1.5;

  @media (width >= 650px) {
    max-width: 485px;
  }

  @media (width >= 1000px) {
    margin: 55px auto 70px;
    max-width: 590px;
    font-size: var(--font-size-20px);
    line-height: 1.4;
  }
}

.rail {
  position: relative;
  margin-top: 70px;
  margin-bottom: 0;
  margin-inline: auto;
  max-width: 1920px; /* magic number */
  overflow: hidden;
}

.embla-viewport {
  padding-inline: var(--spacing-sides);
}

.embla-container {
  --_gap: 16px;

  display: flex;
  gap: var(--_gap);
  touch-action: pan-y pinch-zoom;
}

.embla-slide {
  margin-right: 0;
  min-width: calc(75% - var(--_gap));

  @media (width >= 450px) {
    min-width: calc(50% - var(--_gap));
  }

  @media (width >= 650px) {
    min-width: 275px;
  }
}

.card {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-items: stretch;
  border-radius: var(--radius-large);
  background-color: var(--color-surface-solid-light-lighter);
  min-height: 100%;
}

/* TODO: 1) does background-color do anything? */
.card::after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: -1;
  box-shadow: rgba(0, 15, 30, 0.12) 0 16px 32px;
  background-color: var(--color-surface-glass-dark-80); /* 1 */
  width: calc(100% - 16px);
  height: calc(100% - 32px);
  content: "";
}

.image {
  display: block;
  border-top-right-radius: var(--radius-large);
  border-top-left-radius: var(--radius-large);
  width: 285px;
  height: 187px;
  object-fit: cover;

  @media (width >= 1200px) {
    width: 384px;
    height: 290px;
  }
}

.card-content {
  padding: 16px 16px 24px;
}

.card-title {
  margin: 0;
  color: var(--color-primary);
  font-weight: var(--font-weight-heading);
  font-size: var(--font-size-26px);
  line-height: calc(31 / 26);
  font-family: var(--font-family-heading);
}

/* .card-title--rtl {
  text-align: right;
} */

.card-description {
  margin-top: 8px;
  max-width: 250px;
  font-size: var(--font-size-18px);
  line-height: calc(26 / 18);
}

.link {
  --cosmos-button-font-size: var(--font-size-18px);

  margin-top: 16px;
}

.slider-button {
  display: none;

  @media (width >= 767px) {
    display: unset;
    position: absolute;
    top: 200px;
    z-index: 1;
    width: 48px;
  }
}

@media (width >= 767px) {
  .slider-button--next {
    right: 20px;
  }

  .slider-button--prev {
    left: 20px;
  }
}
