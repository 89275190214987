.navigation {
  display: none;
  position: sticky;
  top: -10px;
  z-index: var(--z-index-lifecycle-nav, 101);
  padding-top: 60px;
  padding-bottom: 20px;
  pointer-events: none;

  @media (width >= 650px) {
    display: flex;
    justify-content: center;
    padding-top: 80px;
  }
}

.navigation-wrapper {
  display: flex;
  flex-wrap: nowrap;
  padding-inline: 20px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  position: relative;
  box-sizing: border-box;
  border: 1px solid var(--color-surface-glass-dark-10);
  border-radius: var(--radius-full);
  background-color: var(--color-surface-solid-light-lighter);
  pointer-events: auto; /* fixed parent wrapper has `pointer-events: none` set */

  &::-webkit-scrollbar {
    display: none;
  }
}

.navigation-inner-wrapper {
  padding-block: 10px;
  white-space: nowrap;
}

.navigation-link {
  position: relative;
  flex: 0 0 auto;
  z-index: 2;
  margin-inline: 20px;
  padding-bottom: 6px;
  color: var(--color-text);
  font-weight: 700;
  font-size: var(--font-size-18px);
  line-height: 1;
  text-decoration: none;
  text-transform: uppercase;

  &::after {
    position: absolute;
    bottom: -3px;
    left: 0;
    transform: scaleX(0);
    transform-origin: center center;
    transition: transform 0.3s;
    background-color: var(--color-primary);
    width: 100%;
    height: 2px;
    content: "";
  }

  &.active::after,
  &:hover::after {
    transform: scaleX(1);
  }
}
