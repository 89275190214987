.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing-xx-tight);
}

.indicator {
  --_size: var(--spacing-x-tight);

  position: relative;
  transition: width 0.5s;
  cursor: pointer;
  border: none;
  border-radius: calc(var(--_size) / 2);
  background-color: var(--color-surface-glass-light-20);
  width: var(--_size);
  height: var(--_size);
}

.indicator-button {
  all: unset;
}

.progress {
  position: absolute;
  inset: 0;
  border-radius: calc(var(--_size) / 2);
  width: 0%;
  height: var(--_size);

  .indicator[data-active="true"] & {
    position: absolute;
    transition: width 2s linear;
    inset: 0;
    background-color: white;
    width: 100%;
    height: var(--_size);
  }
}

.indicator[data-active="true"],
.indicator:hover {
  width: var(--spacing-x-loose);
}
