.container {
  --teaser-basic-text-align: center;
  --button-wrapper-justify-content: center;
  --_can-shadow: var(
    --scheme-can-shadow-rgb,
    var(--can-subtle-shadow-color-rgb)
  );

  position: relative;
  background-color: var(--cosmos-color-surface-solid-light);
  padding-inline: var(--spacing-sides);
  padding-block: var(--spacing-xxx-loose);

  @media (width >= 1000px) {
    --teaser-basic-text-align: start;
    --button-wrapper-justify-content: start;
    display: flex;
    flex-direction: row-reverse;
  }
}

.inner {
  margin-inline: auto;
  width: 100%;
  max-width: var(--max-width-revamp-base);
}

.content {
  display: flex;
  flex-direction: column;

  @media (width >= 1000px) {
    column-gap: 200px;
    flex-direction: row-reverse;
    align-items: center;
  }
}

.can-wrap {
  filter: drop-shadow(
    var(--can-subtle-shadow-values)
      rgb(var(--_can-shadow) / var(--can-subtle-shadow-alpha))
  );
}

.img {
  position: relative;
  margin-inline: auto;
  max-width: 226px;

  @media (width >= 1000px) {
    max-width: 196px;
  }
}

.product-sizes {
  margin-top: var(--spacing-loose);
  margin-inline: auto;

  @media (width >= 1000px) {
    margin-inline: unset;
  }
}

.image-container {
  position: relative;
  width: 100%;
}

.ingredients {
  display: grid;
  position: relative;
  flex-direction: column;
  justify-content: start;
  margin-inline: auto;
  max-width: 500px;
}

/* 1. Child height stretches to fill container */
.ingredient-wrap {
  --_index: var(--index, 1);
  --_indexminusi: var(--indexminusi, 0);
  --_scale: var(--scale, 1);

  display: flex; /* 1 */
  grid-row-start: 1;
  grid-column-start: 1;
}

.ingredient {
  transform: translateY(calc(-16px * var(--_indexminusi))) scale(var(--_scale));
  transform-origin: top center;
  transition: opacity 0.3s linear;
  box-shadow:
    0 -8px 12px 0 rgba(0, 15, 30, 0.04),
    0 2px 4px 0 rgba(0, 15, 30, 0.02),
    0 8px 12px 0 rgba(0, 15, 30, 0.04);
  border-radius: var(--radius-12px);
  background-color: var(--color-surface-solid-light-lighter);
  padding: var(--spacing-normal, 16px);
  width: 100%;

  .ingredient-wrap:not(
      [data-layer="0"],
      [data-layer="1"],
      [data-layer="2"],
      [data-layer="true"]
    )
    & {
    opacity: 0;
  }
}

.offset {
  transform: translateY(calc(16px * var(--_index)));
  transform-origin: top center;
}

.ingredient[data-hidden="true"] {
  opacity: 0.5;
}

.ingredient__title {
  margin-bottom: var(--spacing-x-tight);
}

.left-side {
  position: relative;
}

.ingredients-container {
  margin-top: -140px;
  margin-inline: auto;
  width: 100%;
  max-width: 315px; /* magic number */
}

.pull-tab {
  position: relative;
  bottom: -20px;
  transform: rotateX(-80deg);
  transform-origin: bottom;
  perspective: 300px;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transition: transform 0.3s ease-in-out;
  margin-inline: auto;
  width: 80px;
  height: auto;

  &[data-opened="true"] {
    transform: rotateX(0deg);
    visibility: visible;
  }
}
