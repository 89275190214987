.container {
  --teaser-basic-text-align: center;
  --button-wrapper-justify-content: center;
  --_can-shadow: var(
    --scheme-can-shadow-rgb,
    var(--can-subtle-shadow-color-rgb)
  );

  position: relative;
  background-color: var(--cosmos-color-surface-solid-light);
  padding-inline: var(--spacing-sides);
  padding-block: var(--spacing-xxxx-loose);

  @media (width >= 1000px) {
    --teaser-basic-text-align: start;
    --button-wrapper-justify-content: start;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }
}

.inner {
  margin-inline: auto;
  max-width: var(--max-width-revamp-base);
}

.content {
  display: flex;
  flex-direction: column;

  @media (width >= 1000px) {
    column-gap: 200px;
    flex-direction: row;
    align-items: center;
  }
}

.can-wrap {
  filter: drop-shadow(
    var(--can-subtle-shadow-values)
      rgb(var(--_can-shadow) / var(--can-subtle-shadow-alpha))
  );
}

.img {
  position: relative;
  margin-inline: auto;
  /* magic number */
  max-width: 226px;

  @media (width >= 1000px) {
    /* magic number */
    max-width: 196px;
  }
}

.image-container {
  position: relative;
  width: 100%;
}

.pull-tab {
  position: relative;
  bottom: -20px;
  transform: rotateX(-80deg);
  transform-origin: bottom;
  perspective: 300px;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transition: transform 0.3s ease-in-out;
  margin-inline: auto;
  width: 80px;
  height: auto;

  &[data-opened="true"] {
    transform: rotateX(0deg);
    visibility: visible;
  }
}

.food-marks {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: var(--cosmos-spacing-x-tight);
  padding-block-start: var(--cosmos-spacing-x-loose);

  &[data-size="desktop"] {
    display: none;

    @media (width >= 1000px) {
      display: flex;
      justify-content: flex-start;
      padding-inline: var(--cosmos-spacing-xx-loose);
      padding-block: var(--cosmos-spacing-x-loose);
    }
  }

  &[data-size="mobile"] {
    @media (width >= 1000px) {
      display: none;
    }
  }
}
