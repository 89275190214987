.product-sizes {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-normal, 16px);
  box-shadow: var(--elevation-1-above);

  /* TODO: rename variables after PCS revamp */
  border-radius: var(--radius-12px);
  background-color: var(--color-surface-solid-light-lighter);
  padding: var(--spacing-normal, 16px);
  width: fit-content;
}

.label {
  display: flex;
  align-items: center;
  gap: var(--spacing-x-tight);
}

.label-icon {
  width: 19px;
  height: 19px;
  color: var(--color-surface-glass-dark-45);
}

.list {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-x-tight);
}
